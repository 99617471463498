<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/trade.png" alt />
        <span class="center">财务功能</span>
        <img src="../../assets/right.png" alt />
        <span class="center">企业累计流水统计</span>
      </div>
      <div style="display: flex; align-items: center">
        <el-button type="primary" size="mini" @click="exportBtn()">导出</el-button>
      </div>
    </div>
    <el-card>
      <ListFilter :total="total" @close="searchReset">
        关键字：
        <el-input
          placeholder="请输入企业名称"
          :value="keywords"
          @input="searchChange(1, $event)"
          size="mini"
          style="margin-right: 10px; width: 300px"
          prefix-icon="el-icon-search"
        ></el-input>时间范围：
        <el-radio-group v-model="dateRadio" @change="radioChange()">
          <el-radio-button label="今天" size="mini"></el-radio-button>
          <el-radio-button label="本周" size="mini"></el-radio-button>
          <el-radio-button label="本月" size="mini"></el-radio-button>
          <el-radio-button label="本年" size="mini"></el-radio-button>
          <el-radio-button label="自定义" size="mini"></el-radio-button>
        </el-radio-group>

        <el-date-picker
          v-if="dateRadio === '自定义'"
          :clearable="false"
          @blur="searchChange(2, $event)"
          v-model="dateTime"
          type="daterange"
          size="mini"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
        ></el-date-picker>结算中心：
        <el-select
          :value="pay_platform"
          @change="searchChange(3, $event)"
          style="width:230px;"
          size="mini"
        >
          <el-option
            v-for="(item, index) in platformList"
            :key="index"
            :label="item.shortname"
            :value="item.pay_platform"
          ></el-option>
        </el-select>
      </ListFilter>
      <!-- 总计表 -->
      <p class="title" style="float:left">合计</p>
      <el-table border :data="enterpriseAmount" style="width: 100%">
        <el-table-column label="企业" width="300px" :show-overflow-tooltip="true" fixed>
          <template slot-scope="scope">
            <span>{{ scope.row.pay_platform | platformVal }} 合计</span>
          </template>
        </el-table-column>
        <el-table-column label="收款金额/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.total | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开票金额/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.total | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发放金额/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.out | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="平台服务费/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.free | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="增值税/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.addTax | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="附加税/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.surtax | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="城建/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.urbanTax | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="教育附加/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.educationTax | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="地方教育附加/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.localEducationTax | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="水利基金/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.waterFund | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="税款/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.amountTax | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="服务费/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.pureFree | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付次数" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.times }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结算中心" width="130px">
          <template slot-scope="scope">
            <div class="font-color-black">{{ scope.row.pay_platform | platformVal }}</div>
          </template>
        </el-table-column>
      </el-table>
      <p class="title" style="float:left">明细</p>
      <!-- 结算明细表 -->
      <el-table border :data="list" style="width: 100%" ref="multipleTable">
        <el-table-column label="企业" width="300px" :show-overflow-tooltip="true" fixed>
          <template slot-scope="scope">
            <div class="spanColor" @click="goInfo(scope.row)">{{ scope.row.enterpriseName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="收款金额/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.total | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开票金额/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.total | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发放金额/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.out | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="平台服务费/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.free | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="增值税/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.addTax | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="附加税/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.surtax | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="城建/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.urbanTax | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="教育附加/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.educationTax | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="地方教育附加/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.localEducationTax | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="水利基金/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.waterFund | money }}</span>
          </template>
        </el-table-column>

        <el-table-column label="税款/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.amountTax | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="服务费/元" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.pureFree | money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付次数" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.times }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结算中心" width="130px">
          <template slot-scope="scope">
            <span class="font-color-black">{{ scope.row.pay_platform | platformVal }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import { parseJsonToTable } from '../../lib/dev';
export default {
  components: {
    ListFilter: () => import('/src/components/filter')
  },
  data () {
    return {
      dateRadio: '本月',
      pageNumber: 1,
      dateTime: this.dateTime,
      newTime: moment().format('YYYY-MM-DD hh:mm')
    };
  },
  created () {
    this.setPlatformList(this.$route.params.$preload.platformList);
    this.setPayPlatform(
      this.$route.params.$preload.platformList[0].pay_platform
    );
    this.init();
  },
  computed: {
    ...mapState('statisticsEnterprise', [
      'list',
      'keywords',
      'pay_platform',
      'platformList',
      'pageSize',

      'total',
      'enterpriseAmount'
    ])
  },
  methods: {
    // 列表
    ...mapActions('statisticsEnterprise', ['init', 'updateList', 'getAmount']),
    ...mapMutations('statisticsEnterprise', [
      'setPageNumber',
      'setPageSize',
      'setKeywords',
      'setPayPlatform',
      'setPlatformList',
      'setClear',
      'setDateTime',
      'setEnterpriseStatistics'
    ]),
    handleCurrentChange (val) {
      this.setPageNumber(val);
      this.updateList();
    },
    handleSizeChange (val) {
      this.setPageSize(val);
      this.updateList();
    },
    pageChange (val) {
      this.setPageNumber(val);
      this.updateList();
    },
    sizeChange (val) {
      this.setPageSize(val);
      this.updateList();
    },
    searchChange (id, e) {
      switch (id) {
        case 1:
          this.setKeywords(e);
          this.updateList();
          this.getAmount();
          break;
        case 2:
          this.setDateTime(this.dateTime);
          this.updateList();
          this.getAmount();
          break;
        case 3:
          this.setPayPlatform(e);
          this.updateList();
          this.getAmount();
          break;
      }
    },
    searchReset () {
      this.dateRadio = '本月';
      this.setClear();
      this.updateList();
      this.getAmount();
    },
    // 详情
    goInfo (row) {
      this.setEnterpriseStatistics(row);
      this.$router.push({
        path: '/system/trade/enterpriseStaticsInfo'
      });
    },
    radioChange () {
      this.setClear();
      if (this.dateRadio === '今天') {
        const startDate = moment().format('YYYY-MM-DD');
        const endDate = moment().format('YYYY-MM-DD');
        this.setDateTime([startDate, endDate]);
        this.updateList();
        this.getAmount();
      }
      if (this.dateRadio === '本周') {
        const startDate = moment()
          .week(moment().week())
          .startOf('week')
          .format('YYYY-MM-DD');
        const endDate = moment()
          .week(moment().week())
          .endOf('week')
          .format('YYYY-MM-DD');
        this.setDateTime([startDate, endDate]);
        this.updateList();
        this.getAmount();
      }
      if (this.dateRadio === '本月') {
        const startDate = moment()
          .month(moment().month())
          .startOf('month')
          .format('YYYY-MM-DD');
        const endDate = moment()
          .month(moment().month())
          .endOf('month')
          .format('YYYY-MM-DD');
        this.setDateTime([startDate, endDate]);
        this.updateList();
        this.getAmount();
      }
      if (this.dateRadio === '本年') {
        const startDate = moment()
          .year(moment().year())
          .startOf('year')
          .format('YYYY-MM-DD');
        const endDate = moment()
          .year(moment().year())
          .endOf('year')
          .format('YYYY-MM-DD');
        this.setDateTime([startDate, endDate]);
        this.updateList();
        this.getAmount();
      }
    },
    // 导出
    async exportBtn () {
      let _list = [];
      if (this.total > 500) {
        this.setPageSize(500);
        for (let i = 1; i < this.total / this.pageSize + 1; i++) {
          this.setPageNumber(i);
          await this.updateList();
          _list = [..._list, ...this.list];
        }
      } else {
        this.setPageSize(500);
        this.setPageNumber(1);
        await this.updateList();
        _list = this.list;
      }
      _list = this.list;
      if (_list.length === 0) {
        return this.$message.error('暂无要导出的数据！');
      }
      const time = moment().format('YYMMDDHH');
      parseJsonToTable(
        `企业支付统计${time}`,
        {
          企业名称: 'enterpriseName',
          收款金额: 'total',
          发放金额: 'out',
          平台服务费: 'free',
          增值税: 'addTax',
          // 附加税: 'surtax',
          // 水利基金: 'waterFund',
          城建: 'urbanTax',
          教育附加: 'educationTax',
          地方教育附加: 'localEducationTax',
          税款: 'amountTax',
          服务费: 'pureFree',
          支付次数: 'times',
          结算中心: it => {
            if (it.pay_platform === 'm1') {
              return '陕西结算中心';
            }
            if (it.pay_platform === 'm2') {
              return '青岛结算中心';
            }
            if (it.pay_platform === 'm3') {
              return '江西结算中心';
            }
          }
        },
        [
          ..._list,
          ...this.enterpriseAmount.map(it => {
            return {
              ...it,
              enterpriseName:
                it.pay_platform === 'm1'
                  ? '陕西结算中心 合计'
                  : it.pay_platform === 'm2'
                    ? '青岛结算中心 合计'
                    : it.pay_platform === 'm3'
                      ? '江西结算中心 合计'
                      : '',
              enterpriseId: ''
            };
          })
        ],
        true
      );
    }
  }
};
</script>
<style>
.el-radio-button__inner {
  padding: 7px 20px !important;
  font-size: 12px !important;
  color: #606266;
}
.el-radio-button:first-child .el-radio-button__inner,
.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0;
}
.el-range-editor--mini.el-input__inner {
  top: 1px;
}
</style>
<style scoped lang="scss">
.amountStyle {
  p {
    margin: 5px 0px;
  }
}
.spanColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.font-color-black {
  color: #292525;
}
.title::before {
  display: block;
  content: "";
  width: 5px;
  background-color: #007aff;
}
.title {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 10px;
  font-weight: bold;
}
</style>
